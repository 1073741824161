import Router from './Router';
import { EnvProvider, EnvConfig } from './components';
import { IntlProvider } from './i18n';

export default function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
        <IntlProvider>
          <Router />
        </IntlProvider>
    </EnvProvider>
  );
}
